var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[_c('holders-selection-form',{attrs:{"title":'Select Holder',"holders-list":_vm.holdersList},on:{"update":_vm.updateHolderId},model:{value:(_vm.holder),callback:function ($$v) {_vm.holder=$$v},expression:"holder"}}),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Share terms',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Share Terms","placeholder":"Select share terms","items":_vm.shareTerms,"item-text":"name","item-value":"id","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.formData.share_terms_id),callback:function ($$v) {_vm.$set(_vm.formData, "share_terms_id", $$v)},expression:"formData.share_terms_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Equity incentive plan'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Equity Incentive Plan (Optional)","placeholder":"Select Equity incentive plan","items":_vm.eips,"item-text":"name","item-value":"id","error-messages":errors,"hide-details":errors.length === 0,"clearable":""},model:{value:(_vm.formData.equity_incentive_plan_id),callback:function ($$v) {_vm.$set(_vm.formData, "equity_incentive_plan_id", $$v)},expression:"formData.equity_incentive_plan_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Financing round'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Financing Round(Optional)","placeholder":"Select financing round","items":_vm.financingRounds,"item-text":"name","item-value":"id","error-messages":errors,"hide-details":errors.length === 0,"clearable":""},model:{value:(_vm.formData.financing_round_id),callback:function ($$v) {_vm.$set(_vm.formData, "financing_round_id", $$v)},expression:"formData.financing_round_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Stock registration name',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Stock Registration Name","color":"primary","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.stockRegistrationName),callback:function ($$v) {_vm.stockRegistrationName=$$v},expression:"stockRegistrationName"}})]}}])})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Grant date',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"error-messages":errors,"input-templ":{label: 'Grant date', mandatory: true},"hide-details":errors.length === 0,"clearable":""},model:{value:(_vm.grantDate),callback:function ($$v) {_vm.grantDate=$$v},expression:"grantDate"}})]}}])})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Board approval date',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"error-messages":errors,"hide-details":errors.length === 0,"input-templ":{label: 'Board approval date', mandatory: true},"clearable":""},model:{value:(_vm.boardApprovalDate),callback:function ($$v) {_vm.boardApprovalDate=$$v},expression:"boardApprovalDate"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":'Granted shares',"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Granted Shares","color":"primary","error-messages":errors,"hide-details":errors.length === 0,"options":{ locale: 'en-US', precision: 0 }},on:{"input":_vm.calculate},model:{value:(_vm.formData.number),callback:function ($$v) {_vm.$set(_vm.formData, "number", $$v)},expression:"formData.number"}})]}}])})],1),_c('v-col',{staticClass:"text-center mt-5",attrs:{"cols":"1"}},[_vm._v(" X ")]),_c('v-col',[_c('validation-provider',{attrs:{"name":'Price per share',"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Price Per Share","color":"primary","error-messages":errors,"hide-details":errors.length === 0,"options":{ locale: 'en-US', precision: 2 },"prepend-inner-icon":"mdi-currency-usd"},on:{"input":_vm.calculate},model:{value:(_vm.pricePerShare),callback:function ($$v) {_vm.pricePerShare=$$v},expression:"pricePerShare"}})]}}])})],1),_c('v-col',{class:{ 'mt-3': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"cols":"12","md":"3"}},[(!_vm.calculatedAmount)?_c('span',[_vm._v("= NA")]):_c('span',[_vm._v("= "+_vm._s(_vm.$currencyWithPrecision(_vm.calculatedAmount))+" ")]),_c('div',{staticClass:"trsText--text text-body-2 text--lighten-1 mt-1"},[_vm._v(" Invested capital ")])])],1),_c('v-row',[_c('v-col',[_c('common-vesting-form',{model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('notes-and-documents-form',{attrs:{"id":_vm.shareId,"type":_vm.CONSTS.NOTES_DOCUMENT_API_TYPE.SHARES,"is-submit":_vm.isSubmit},on:{"navToList":function($event){return _vm.$emit('navToList', true)}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }