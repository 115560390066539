<template>
  <v-form ref="form">
    <holders-selection-form
      v-model="holder"
      :title="'Select Holder'"
      :holders-list="holdersList"
      @update="updateHolderId"
    />
    <v-row class="mt-0">
      <v-col
        cols="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Share terms'"
          :rules="{ required: true }"
        >
          <common-input-select
            v-model="formData.share_terms_id"
            label="Share Terms"
            placeholder="Select share terms"
            :items="shareTerms"
            item-text="name"
            item-value="id"
            :error-messages="errors"
            :hide-details="errors.length === 0"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Equity incentive plan'"
        >
          <common-input-select
            v-model="formData.equity_incentive_plan_id"
            label="Equity Incentive Plan (Optional)"
            placeholder="Select Equity incentive plan"
            :items="eips"
            item-text="name"
            item-value="id"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            clearable
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Financing round'"
        >
          <common-input-select
            v-model="formData.financing_round_id"
            label="Financing Round(Optional)"
            placeholder="Select financing round"
            :items="financingRounds"
            item-text="name"
            item-value="id"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            clearable
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Stock registration name'"
          :rules="{ required: true }"
        >
          <common-input-text-field
            v-model="stockRegistrationName"
            label="Stock Registration Name"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="6"
        md="6"
        class="pb-0"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Grant date'"
          :rules="{ required: true }"
        >
          <input-template-date
            v-model="grantDate"
            :mask="'##/##/####'"
            :error-messages="errors"
            :input-templ="{label: 'Grant date', mandatory: true}"
            :hide-details="errors.length === 0"
            clearable
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="6"
        md="6"
        class="pb-0"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Board approval date'"
          :rules="{ required: true }"
        >
          <input-template-date
            v-model="boardApprovalDate"
            :mask="'##/##/####'"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            :input-templ="{label: 'Board approval date', mandatory: true}"
            clearable
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <validation-provider
          v-slot="{ errors }"
          :name="'Granted shares'"
          :rules="{ required: true, min_value: 0 }"
        >
          <common-input-currency-input
            v-model="formData.number"
            label="Granted Shares"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            :options="{ locale: 'en-US', precision: 0 }"
            @input="calculate"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="1"
        class="text-center mt-5"
      >
        X
      </v-col>
      <v-col>
        <validation-provider
          v-slot="{ errors }"
          :name="'Price per share'"
          :rules="{ required: true, min_value: 0 }"
        >
          <common-input-currency-input
            v-model="pricePerShare"
            label="Price Per Share"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            :options="{ locale: 'en-US', precision: 2 }"
            prepend-inner-icon="mdi-currency-usd"
            @input="calculate"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        md="3"
        :class="{ 'mt-3': $vuetify.breakpoint.mdAndUp }"
      >
        <span v-if="!calculatedAmount">= NA</span>
        <span v-else>= {{ $currencyWithPrecision(calculatedAmount) }} </span>
        <div class="trsText--text text-body-2 text--lighten-1 mt-1">
          Invested capital
        </div>
      </v-col>
    </v-row>
    <!-- Vesting Section -->
    <v-row>
      <v-col>
        <common-vesting-form
          v-model="formData"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <notes-and-documents-form
          :id="shareId"
          :type="CONSTS.NOTES_DOCUMENT_API_TYPE.SHARES"
          :is-submit="isSubmit"
          @navToList="$emit('navToList', true)"
        />
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
  import moment from 'moment'
  import InputTemplateDate from '@/components/common/fmsvc/form-templates/InputTemplateDate'
  import HoldersSelectionForm from './HoldersSelectionForm'
  import CommonVestingForm from '@/components/common/captable/components/forms/CommonVestingForm'
  import NotesAndDocumentsForm from './NotesAndDocumentsForm.vue'

  export default {
    name: 'SharesForm',

    components: {
      HoldersSelectionForm,
      CommonVestingForm,
      NotesAndDocumentsForm,
      InputTemplateDate,
    },

    props: {
      value: {
        type: Object,
        required: true,
      },
      holder: {
        type: Object,
        required: true,
      },
      holdersList: {
        type: Array,
        required: true,
      },
      shareTerms: {
        type: Array,
        required: true,
      },
      financingRounds: {
        type: Array,
        required: true,
      },
      eips: {
        type: Array,
        required: true,
      },
      shareId: {
        type: String,
      },
      isSubmit: {
        type: Boolean,
      },
    },

    data: () => ({
      calculatedAmount: null,
      notesData: {},
      grantDate: '',
      boardApprovalDate: '',
      pricePerShare: 0,
      stockRegistrationName: '',
    }),

    computed: {
      formData: {
        get () {
          return this.value || {}
        },
        set (val) {
          this.$emit('input', val)
        },
      },
      selectedFinancingRounds: {
        get () {
          return this.formData.financing_round_id || ''
        },
        set (val) {
          this.$emit('input', val)
        },
      },
      selectedSharedTerms: {
        get () {
          return this.formData.share_terms_id || ''
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
      value: {
        handler (val) {
          if (val) {
            this.grantDate = val.granted_on ? moment(val.granted_on).format('MM/DD/YYYY') : ''
            this.boardApprovalDate = val.board_approved_on ? moment(val.board_approved_on).format('MM/DD/YYYY') : ''
          }
        },
        immediate: true,
      },
      grantDate: {
        handler (val) {
          if (val) {
            this.formData.granted_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      boardApprovalDate: {
        handler (val) {
          if (val) {
            this.formData.board_approved_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      selectedFinancingRounds: {
        handler (val) {
          if (val) {
            const PPR = this.financingRounds.find(round => round.id === val)
            this.pricePerShare = PPR.price_per_share
            this.formData.price_per_share = PPR.price_per_share
          } else {
            this.pricePerShare = 0
            this.formData.price_per_share = 0
          }
          this.calculate()
        },
        immediate: true,
      },
      pricePerShare: {
        handler (val) {
          if (val) {
            this.calculate()
          }
        },
        immediate: true,
      },
      selectedSharedTerms: {
        handler (val) {
          if (val) {
            const sharedTerm = this.shareTerms.find(term => term.id === val)
            if (sharedTerm.type) {
              this.stockRegistrationName = `${this.prefix(sharedTerm.type)}-`
            }
            if (sharedTerm.board_approved_on) {
              this.boardApprovalDate = moment(sharedTerm.board_approved_on).format('MM/DD/YYYY')
            } else {
              this.boardApprovalDate = ''
            }
          }
        },
        immediate: true,
      },
      stockRegistrationName: {
        handler (val) {
          if (val) {
            this.formData.registration_name = val
          }
        },
        immediate: true,
      },
    },

    methods: {
      updateHolderId (val) {
        this.$emit('updateHolder', val)
      },
      calculate () {
        this.calculatedAmount = this.formData.number * this.pricePerShare
        this.formData.price_per_share = this.pricePerShare
      },
      prefix (type) {
        if (type === this.CONSTS.SHARE_TERM_TYPES.COMMON) {
          return this.CONSTS.SECURITIES_PREFIX.COMMON
        } else if (type === this.CONSTS.SHARE_TERM_TYPES.PREFERRED) {
          return this.CONSTS.SECURITIES_PREFIX.PREFERRED
        }
      },
    },
  }
</script>
