<template>
  <common-trs-page-container :padding="0">
    <template v-slot:page-content>
      <!-- <common-app-page-header
        :title="'Draft Share'"
      /> -->
      <v-card-title class="mt-3 mb-7 pt-6 pl-7">
        <span
          class="pr-3 cursor-pointer"
          @click="close"
        > <v-icon>
          mdi-arrow-left</v-icon></span> <span class="trsText--text font-weight-medium text-h4">Draft Share</span>
      </v-card-title>
      <common-trs-card class="mx-8 pb-0 mb-2">
        <validation-observer
          ref="observer"
          v-slot="{ valid }"
          class="ml-auto"
        >
          <v-card-text class="pa-0">
            <form>
              <shares-form
                v-model="sharesData"
                :holders-list="investors"
                :holder="holder"
                :share-terms="shareTerms"
                :share-id="shareId"
                :financing-rounds="financingRounds"
                :eips="eips"
                :is-submit="isSubmit"
                @updateHolder="updateHolder"
                @navToList="navToList"
              />
            </form>
          </v-card-text>
          <v-divider />
          <v-row class="d-flex justify-space-between">
            <v-col>
              <common-trs-btn
                type="secondary"
                medium
                class="pull-right mr-2"
                text
                :to="{name: 'DraftSecurities', query: { tab: 0 }}"
              >
                Cancel
              </common-trs-btn>
            </v-col>
            <v-col class="text-right">
              <common-trs-btn
                type="primary"
                medium
                class="white--text"
                text
                :disabled="!valid"
                :loading="loading"
                @click="save"
              >
                Save
              </common-trs-btn>
            </v-col>
          </v-row>
        </validation-observer>
      </common-trs-card>
    </template>
  </common-trs-page-container>
</template>

<script>
  import SharesForm from '@/components/common/captable/components/forms/SharesForm'
  import * as captableService from '@/components/common/captable/services/captable'

  export default {
    name: 'DraftShares',

    components: {
      SharesForm,
    },

    data: () => ({
      loading: false,
      sharesData: {},
      holder: {},
      investors: [],
      shareTerms: [],
      financingRounds: [],
      eips: [],
      shareId: '',
      isSubmit: false,
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    watch: {
      internalDialog: {
        handler (val) {
          if (val) {
            this.isSubmit = false
          }
        },
      },
    },

    mounted () {
      this.loadIssuerProfileList()
      this.loadShareTermList()
      this.loadFinancingRoundList()
      this.loadEipList()
    },

    methods: {
      async save () {
        this.loading = true
        if (!this.sharesData.holder_id) {
          this.sharesData.holder_id = await this.createNewInvestor()
        }
        if (this.sharesData.equity_incentive_plan_id && this.sharesData.financing_round_id) {
          this.$store.dispatch('app/handleError', {
            message: 'Sorry! Please set only one of Financing round Id or Eip Id.',
          })
          this.loading = false
        } else {
          if (this.sharesData.holder_id) {
            if (this.sharesData.equity_incentive_plan_id === null) delete this.sharesData.equity_incentive_plan_id
            if (this.sharesData.financing_round_id === null) delete this.sharesData.financing_round_id
            if (this.sharesData.vesting_schedule && this.sharesData.vesting_schedule.custom && this.sharesData.vesting_schedule.custom.length > 0) {
              const result = this.sharesData.vesting_schedule.custom.map(item => {
                return { ...item, percent: item.percent / 100 }
              })
              this.sharesData.vesting_schedule.custom = result
            } else {
              if (this.sharesData && this.sharesData.vesting_schedule && this.sharesData.vesting_schedule.time_based && this.sharesData.vesting_schedule.time_based.cliff) {
                this.sharesData.vesting_schedule.time_based.cliff = { ...this.sharesData.vesting_schedule.time_based.cliff, percent: this.sharesData.vesting_schedule.time_based.cliff.percent / 100 }
              }
            }
            try {
              this.clearNullData()
              const resp = await captableService.draftShares(this.captableId, this.sharesData)
              if (resp.data.id) {
                this.shareId = resp.data.id
                this.isSubmit = true
              }
            } catch (err) {
              this.loading = false
              this.$store.dispatch('app/handleError', {
                err,
                message: 'Sorry! Unable to draft share. Please try again later.',
              })
            }
          }
        }
      },

      navToList () {
        this.loading = false
        this.$store.dispatch('app/message', { text: 'Share drafted successfully.' })
        this.$router.push({ name: 'DraftSecurities', query: { tab: 0 } })
      },
      close () {
        this.$router.push({ name: 'DraftSecurities', query: { tab: 0 } })
      },
      async createNewInvestor () {
        try {
          const resp = await captableService.draftProfile(this.captableId, this.holder)
          const id = resp && resp.data.id
          await captableService.publishProfile(this.captableId, id)
          return id
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: `Sorry! Unable to draft share for investor ${this.holder.name}. Please try again later.`,
          })
        }
      },

      updateHolder (val) {
        if (val.to_holder_id) {
          this.sharesData.holder_id = val.to_holder_id
        } else {
          this.sharesData.holder_id = null
          delete val.to_holder_id
          this.holder = val
        }
      },

      async loadIssuerProfileList () {
        try {
          const resp = await captableService.getIssuerProfileList(this.captableId)
          this.investors = resp.data.profiles
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
      },
      async loadShareTermList () {
        try {
          const resp = await captableService.getShareTermList(this.captableId)
          this.shareTerms = resp.data.share_terms
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
      },
      async loadFinancingRoundList () {
        try {
          const resp = await captableService.getAllFinancingRounds(this.captableId)
          if (resp && resp.data) {
            this.financingRounds = resp.data.financing_rounds
          }
        } catch (e) {}
      },
      async loadEipList () {
        try {
          const resp = await captableService.getEipList(this.captableId)
          if (resp && resp.data) {
            this.eips = resp.data.equity_incentive_plans
          }
        } catch (e) {}
      },
      clearNullData () {
        Object.keys(this.sharesData).forEach((key) => {
          if (this.sharesData[key] === null || this.sharesData[key] === undefined) {
            delete this.sharesData[key]
          }
          if (this.sharesData.vesting_schedule) {
            Object.keys(this.sharesData.vesting_schedule).forEach((key) => {
              if (this.sharesData.vesting_schedule[key] === undefined || this.sharesData.vesting_schedule[key] === null || Object.entries(this.sharesData.vesting_schedule[key]).length === 0) {
                delete this.sharesData.vesting_schedule[key]
              }
            })
          }
        })
      },
    },
  }
</script>
